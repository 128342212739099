/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    font-family: "Quicksand";
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    font-family: "Quicksand";
    display: block;
}
body {
    line-height: 1;
}
ol,
ul {
    /* list-style: none; */
}
blockquote,
q {
    quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: "";
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

body {
    font-family: "Nunito";
    margin: 0;
    font-size: 16px;
    font-weight: 800;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    color: #444;
    overflow-x: hidden;
}

.small-outlined-text {
    letter-spacing: 0.03em;
    font-size: 24px;
    color: rgba(22, 24, 30, 0.7);
    /* -webkit-text-stroke: 3px rgba(22, 24, 30, 0.7); */
    paint-order: stroke fill;
}

.large-outlined-text {
    letter-spacing: 0.03em;
    font-size: 40px;
    color: rgba(22, 24, 30, 0.7);
    /* -webkit-text-stroke: 4px rgba(22, 24, 30, 0.7); */
}
/* p, h1, h2, h3 {
  border: 4px solid rgba(22, 24, 30, 0.7);
} */

button {
    border: none;
    padding: 0;
    font-family: "Quicksand";
    font-weight: 800;
    user-select: none;
}

body > iframe {
    display: none;
}

.wrapperClassName {
    border: 1px solid grey;
    border-radius: 3px;
    max-width: 600px;
}

.editorClassName {
    padding: 10px;
}

button:enabled {
    cursor: pointer;
}

button img {
    user-select: none;
    pointer-events: none;
}

@media (hover: hover) {
    button:enabled.brightness-highlight:hover {
        filter: brightness(120%);
    }
}

button:enabled.brightness-highlight:active {
    filter: brightness(160%);
}


#preloaded-audio-container{
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
    height: 0;
    width: 0;
    z-index: -1000;
}
